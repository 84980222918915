import { useEffect } from "react";

const restrictToRange = (val) => Math.max(Math.min(1, val), 0);

const MOBILE_MAX_WIDTH = 767;

const useExpandingContainer = ({ expand, outsideRef, containerRef }) => {
  const updateWidth = () => {
    if (!outsideRef.current || !containerRef.current) {
      return;
    }

    const parentWidth = outsideRef.current.getBoundingClientRect().width;
    const containerRect = containerRef.current.getBoundingClientRect();

    containerRef.current.style.overflowX = "hidden";

    // force 100% for mobile
    if (window.innerWidth <= MOBILE_MAX_WIDTH) {
      containerRef.current.style.marginInlineStart = `-${
        (document.documentElement.clientWidth - parentWidth) / 2
      }px`;

      // don't use vw because it includes the width of the scrollbar
      containerRef.current.style.width = `${document.documentElement.clientWidth}px`;

      return;
    }

    const containerPageY = containerRect.y + window.scrollY;

    // if the element starts above the viewport, starting scroll height is 0
    // otherwise the starting scroll height is the minimum height for the element to be scrolled into view
    const startingScrollHeight = Math.max(
      containerPageY - window.innerHeight,
      0,
    );

    const endingScrollHeight = containerPageY;

    const transitionFraction = restrictToRange(
      (window.scrollY - startingScrollHeight) /
        (endingScrollHeight - startingScrollHeight),
    );

    const scaledWidth =
      parentWidth +
      (document.documentElement.clientWidth - parentWidth) * transitionFraction;

    // assuming the container was centred to start with
    const horizontalOffset =
      ((document.documentElement.clientWidth - parentWidth) *
        transitionFraction) /
      2;

    containerRef.current.style.marginInlineStart = `-${horizontalOffset}px`;

    // don't use vw because it includes the width of the scrollbar
    containerRef.current.style.width = `${scaledWidth}px`;
  };

  useEffect(() => {
    if (!expand) {
      return () => {
        if (containerRef.current) {
          containerRef.current.style.marginInlineStart = "";
          containerRef.current.style.width = "";
        }
      };
    }

    window.addEventListener("scroll", updateWidth);

    const resizeObserver = new ResizeObserver(() => updateWidth());
    resizeObserver.observe(document.documentElement);

    return () => {
      window.removeEventListener("scroll", updateWidth);
      resizeObserver.unobserve(document.documentElement);
    };
  }, [expand]);
};

export default useExpandingContainer;

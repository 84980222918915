import React, { useRef } from "react";
import Placeholder from "../../../../core/Components/Placeholder";
import {
  calculateSpacing,
  getBackgroundPosition,
  getBackgroundSize,
  getHorizontalAlign,
  getVerticalAlign,
  joinClasses,
} from "../../../../utils/getContainerClasses";
import useIsMobile from "../../../../utils/useIsMobile";
import useExpandingContainer from "../../../../utils/useExpandingContainer";
import GenericImage from "../../../content/_generic/GenericImage/GenericImage";
import buildPatternBackground from "../buildPatternBackground";
import { Controller, Scene } from "react-scrollmagic";

const OneColumnContainer = ({ rendering, fields }) => {
  const outsideRef = useRef(null);
  const containerRef = useRef(null);

  useExpandingContainer({
    expand: fields.expandingContainer?.value,
    outsideRef,
    containerRef,
  });

  const isMobile = useIsMobile();

  const MobileExternalBackground = ({ position }) => (
    <div
      className={`mobile-external-background ${
        position === "before" ? "mb-4" : "mt-4"
      }`}
    >
      <GenericImage
        fields={{
          image: fields.backgroundImage,
          roundedCorners: fields.roundedCorners,
        }}
      />
    </div>
  );

  const patternBackgroundString = buildPatternBackground({
    isMobile,
    patternBackgroundLeft: fields.patternBackgroundLeft,
    patternBackgroundRight: fields.patternBackgroundRight,
  });

  return (
    <section
      id={fields.id?.value}
      className={joinClasses(
        "column-container",
        fields.backgroundColor?.name && `bg-${fields.backgroundColor.name}`,
        getBackgroundSize(fields.backgroundSize?.name),
        getBackgroundPosition(fields.backgroundPosition?.name),
        fields.backgroundMobileExternalPosition?.name &&
          "has-mobile-external-background",
        (fields.backgroundImageRtlFlip?.value || patternBackgroundString) &&
          "background-image-rtl-flip",
        fields.roundedCorners?.value && "rounded",
        fields.fillHeight?.value && "full-height",
        fields.outerClassName?.value,
        calculateSpacing(fields),
      )}
      ref={outsideRef}
      style={
        patternBackgroundString
          ? { background: patternBackgroundString }
          : fields.backgroundImage?.value?.src
            ? { backgroundImage: `url('${fields.backgroundImage.value.src}')` }
            : undefined
      }
    >
      <div
        className={fields.useContainer?.value ? "container" : undefined}
        ref={containerRef}
      >
        <div
          className={joinClasses(
            "row",
            getHorizontalAlign(fields.horizontalAlign?.name),
            getVerticalAlign(fields.verticalAlign?.name),
          )}
        >
          {fields.backgroundMobileExternalPosition?.name === "before" &&
          fields.backgroundImage?.value?.src ? (
            <MobileExternalBackground position="before" />
          ) : null}

          { fields.snapToGridContainer?.value ? (
            <Controller>
              <Scene
                triggerHook="onLeave"
                duration={`${fields.snapDuration?.value || "400%"}`}
                pin
              >
                {(progress) => (
                  <section
                    className={`pinContainer yellow panel-${fields.id?.value}`}
                  >
                    <Placeholder
                      name="one-column-content"
                      progress={progress}
                      rendering={rendering}
                    />
                  </section>
                )}
              </Scene>
            </Controller>
          ) : (
            <div className={`col-md-${fields.col?.value || 12}`}>
              <Placeholder name="one-column-content" rendering={rendering} />
            </div>
          )}

          {fields.backgroundMobileExternalPosition?.name === "after" &&
          fields.backgroundImage?.value?.src ? (
            <MobileExternalBackground position="after" />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default OneColumnContainer;
